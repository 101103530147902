// src/data/articles.js

const articles = [
    {
      id: 1,
      title: "Privacy",
      date: "Aug 7, 2024",
      url: "/privacy",
      category: "Article"
    },
    // {
    //   id: 2,
    //   title: "How to quickly deploy a static website",
    //   date: "Jul 20, 2023",
    //   url: "/deploy-static-website",
    //   category: "Tutorial"
    // },
    // {
    //   id: 3,
    //   title: "Our first project with React",
    //   date: "Jun 5, 2023",
    //   url: "/first-react-project",
    //   category: "Article"
    // }
  ];
  
  export default articles;