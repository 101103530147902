import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import articles from './data/articles';
import './App.css';

// Placeholder component for article pages
const ArticlePage = ({ title }) => (
  <div className="min-h-screen bg-white dark:bg-gray-900 py-8 px-4">
    <div className="max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">{title}</h1>
      <p className="text-gray-600 dark:text-gray-300">Content for {title} goes here.</p>
    </div>
  </div>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        {articles.map(article => (
          <Route 
            key={article.id} 
            path={article.url} 
            element={<ArticlePage title={article.title} />} 
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;