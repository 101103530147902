import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 py-8 px-4">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Privacy Policy</h1>
        <div className="prose dark:prose-invert">
          <p><strong>Effective Date: 7 Aug 2024</strong></p>

          <h2 className="mt-8"><strong>1. Introduction</strong></h2>
          <p>Welcome to 6uoprogrammer's Privacy Policy. We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and share your information when you use our games available on Google Play.</p>

          <h2 className="mt-8"><strong>2. Information We Collect</strong></h2>
          <p><strong>2.1. Personal Data</strong></p>
          <p>We may collect personal information that you provide directly to us, such as:</p>
          <ul>
            <li>Email address</li>
          </ul>
          <p><strong>2.2. Usage Data</strong></p>
          <p>We collect information about your interactions with our games, including:</p>
          <ul>
            <li>Device information (device type, operating system, etc.)</li>
            <li>Usage statistics (game progress, in-app purchases, etc.)</li>
          </ul>

          <h2 className="mt-8"><strong>3. How We Use Your Information</strong></h2>
          <p>We use your information for various purposes, including:</p>
          <ul>
            <li>Providing and improving our games</li>
            <li>Personalizing your gaming experience</li>
            <li>Communicating with you about updates, promotions, and support</li>
            <li>Analyzing usage patterns to enhance our services</li>
            <li>Ensuring legal compliance</li>
          </ul>

          <h2 className="mt-8"><strong>4. Sharing Your Information</strong></h2>
          <p>The app does use third-party services that may collect information used to identify you.</p>
          <p>Link to the privacy policy of third-party service providers used by the app:</p>
          <ul>
            <li><a href="https://6uogames.com/legal/privacy-policy" className="text-blue-600 underline">https://6uogames.com/legal/privacy-policy</a></li>
          </ul>

          <h2 className="mt-8"><strong>5. Security</strong></h2>
          <p>We implement reasonable security measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure.</p>

          <h2 className="mt-8"><strong>6. Your Rights</strong></h2>
          <p>Depending on your jurisdiction, you may have the following rights regarding your personal data:</p>
          <ul>
            <li>Access: You can request access to your personal data.</li>
            <li>Correction: You can request correction of inaccurate or incomplete data.</li>
            <li>Deletion: You can request deletion of your personal data.</li>
            <li>Objection: You can object to the processing of your data.</li>
            <li>Portability: You can request a copy of your data in a portable format.</li>
          </ul>

          <h2 className="mt-8"><strong>7. Children's Privacy</strong></h2>
          <p>Our games are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.</p>

          <h2 className="mt-8"><strong>8. Changes to This Privacy Policy</strong></h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" above.</p>

          <h2 className="mt-8"><strong>9. Contact Us</strong></h2>
          <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
          <p>Email: <a href="mailto:6uoprogrammer@gmail.com" className="text-blue-600 underline">6uoprogrammer@gmail.com</a></p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
